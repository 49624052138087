import React from "react"
import ImageGallery from "./ImageGallery"
import "./usp.css"

const UniqueSellingPoint = ({ title, desc, images = [] }) => {
  return (
    <div className="usp-container">
      <div className="usp-gap" />
      <div className="usp">
        <ImageGallery images={images} />
        <div className="usp-title">
          <h3>{title}</h3>
          <p>
            {desc.split("\n").map(paragraph => (
              <span key={Math.random()}>
                {paragraph}
                <br />
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UniqueSellingPoint
