import React from "react"
import ImageAssessment1 from "../components/images/image-assessment1"
import ImageAssessment2 from "../components/images/image-assessment2"
import ImageAssessment3 from "../components/images/image-assessment3"
import ImageDarkMode from "../components/images/image-dark-mode"
import ImageInlesson from "../components/images/image-inlesson"
import ImageInlesson2 from "../components/images/image-inlesson2"
import ImageIntro from "../components/images/image-intro"
import ImageNotitifcations from "../components/images/image-notifications"
import ImageStats1 from "../components/images/image-stats1"
import ImageStats2 from "../components/images/image-stats2"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UniqueSellingPoint from "../components/UniqueSellingPoint"

const IndexPage = () => (
  <Layout>
    <SEO title="Start" />
    <h1>Vereinfacht unterrichten – mit dem Lehrerbuch</h1>
    <ul>
      <li>
        Leistungen, Fehlzeiten, Verspätungen schnell und einfach erfassen.
      </li>
      <li> Einfache Eingabe von Leistungsnachweisen.</li>
      <li>
        Übersichtliche Statistiken für Schüler, den Klassenverband und Fächer.
      </li>
      <li>
        Automatisierte Benachrichtigung der Eltern über fehlende Hausaufgaben,
        Verspätungen, gutes und schlechtes Sozialverhalten und Mitarbeit.
      </li>
    </ul>
    <UniqueSellingPoint
      title="Klassenbuch-Funktionalität"
      images={[<ImageInlesson />, <ImageInlesson2 />]}
      desc="Am Anfang einer Stunde wählen Sie in der App Fach und Klasse aus beginnen mit dem Unterricht. Jetzt können Fehlzeiten, Verspätungen, vergessene Hausaufgaben, sowie Punkte  für gute oder schlechte Mitarbeit und Sozialverhalten vergeben werden."
    />
    <UniqueSellingPoint
      images={[<ImageNotitifcations />]}
      title="Automatisierte Benachrichtigungen"
      desc="Das Lehrerbuch bietet die Möglichkeit, automatisierte Benachrichtigungen an die Eltern zu senden, zum Beispiel bei guter Mitarbeit, vielfach vergessenen Hausaufgaben, oder mehrmaliger Verspätung. Das kann eine E-Mail oder SMS sein, die direkt vom Gerät gesendet werden kann, sofern diese Funktionalität auf dem Gerät eingerichtet ist.
Die Textvorlage kann in den Einstellungen individuell angepasst werden."
    />
    <UniqueSellingPoint
      images={[
        <ImageAssessment1 />,
        <ImageAssessment2 />,
        <ImageAssessment3 />,
      ]}
      title="Bewertungen"
      desc="Unterstützung für D, AT, CH und Südtiroler Bewertungssysteme. Leistungsnachweise jeglicher Art können angelegt werden. Jeder Nachweis hat zusätzlich eine Gewichtung, das heißt, für wichtige Prüfungen wie Klausuren kann die Note doppelt bis dreifach zählen."
    />
    <UniqueSellingPoint
      images={[<ImageStats1 />, <ImageStats2 />]}
      title="Umfangreiche Statistiken"
      desc={`Die erfassten Daten werden, grafisch aufbereitet, überschaubar gemacht.
Vom Klassendurchschnitt bis zum Verhalten in einzelnen Fächern, vom Leistungsverlauf und Auffälligkeiten in bestimmten Kategorien ist alles offensichtlich und objektiv dargestellt. Somit können Sie zum Beispiel beim Elterngespräch leicht verdeutlichen, wie sich der Schüler entwickelt hat.`}
    />
    <UniqueSellingPoint
      title="Schnelle Einrichtung und Bedienung"
      images={[<ImageIntro />]}
      desc="Mit Fokus auf komfortabler Bedienung und Schnelligkeit, erleichert das Lehrerbuch die Arbeit, indem es die wiederkehrenden Aufgaben wegabstrahiert. Anlegen neuer Schüler und die ganze Verwaltung ist so einfach wie möglich gehalten. Wir planen auch in Zukunft Schnittstellen zu verschiedenen Systemen anzubieten, damit Schüler und Klassen einfacher in die App geladen werden können."
    />
    <UniqueSellingPoint
      images={[<ImageDarkMode />]}
      title="Dunkelmodus"
      desc="Für Nachteulen, Videovorträge und so weiter gibt es auch einen Dunkelmodus."
    />
  </Layout>
)

export default IndexPage
