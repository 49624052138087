import React, { useState, useRef, useEffect } from "react"
import SwipeableViews from "react-swipeable-views"

let scrollTout = 0
let isScrolling = false
let scrollTarget = 0

const ImageGallery = ({ images = [] }) => {
  const [selectedImage, setSelectedImage] = useState(0)
  const myRef = useRef(null)
  const [wstyle, setWstyle] = useState(420)

  const goon = (inx = -1) => {
    console.log(inx)
    if (!isNaN(inx) && inx > -1) {
      setSelectedImage(inx)
      return
    }
    setSelectedImage(selectedImage + 1 >= images.length ? 0 : selectedImage + 1)
  }

  useEffect(() => {
    setWstyle((myRef.current.clientWidth * images.length).toString() + "px")
  }, [myRef])

  useEffect(() => {
    const { clientWidth } = myRef.current
    scrollTarget = selectedImage * clientWidth
    myRef.current.scrollLeft = scrollTarget
  }, [selectedImage])

  const hasScrolled = e => {
    if (!isScrolling) {
      clearTimeout(scrollTout)
      const { clientWidth } = myRef.current

      scrollTout = setTimeout(hasCompletedScroll, clientWidth)
    }
  }
  const hasCompletedScroll = () => {
    isScrolling = true
    const { scrollLeft, clientWidth } = myRef.current
    const np =
      Math.floor(Math.round(scrollLeft + clientWidth / 2) / clientWidth) *
      clientWidth
    scrollTarget = np
    triggerNudge()
  }

  const nudgePositionScroll = () => {
    clearTimeout(scrollTout)
    const { scrollLeft } = myRef.current

    if (scrollTarget < scrollLeft) {
      const tp = Math.ceil((scrollLeft - scrollTarget) / 2)
      myRef.current.scrollLeft -= tp
      triggerNudge()
    } else if (scrollTarget > scrollLeft) {
      let nvc = Math.ceil((scrollTarget - scrollLeft) / 2)
      myRef.current.scrollLeft += nvc
      triggerNudge()
    } else {
      myRef.current.scrollLeft = scrollTarget
      clearTimeout(scrollTout)
      setTimeout(() => {
        isScrolling = false
      }, 100)
    }
  }

  const triggerNudge = () => {
    clearTimeout(scrollTout)
    scrollTout = setTimeout(nudgePositionScroll, 20)
  }

  return (
    <div className="usp-image-gallery">
      <div ref={myRef} className="image-gallery-wrapper" onScroll={hasScrolled}>
        <div onClick={goon} className="image-gallery" style={{ width: wstyle }}>
          {images.map(img => (
            <div key={Math.random()} className="single-image">
              {img}
            </div>
          ))}
        </div>
      </div>
      <div className="skipper">
        {images.length > 1
          ? images.map((img, inx) => (
              <div
                onClick={() => goon(inx)}
                className={
                  "img-icon" + (inx === selectedImage ? " active" : "")
                }
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default ImageGallery
